import React, { useState, useEffect } from "react";
import Flag from "./svg/UkraineFlag";
import { jedideskSiteUrl } from "../const/const";

const onClickLabelHandler = () => {
  window.open(jedideskSiteUrl, "blank");
};

const Label = ({ isMobile, isChatLabel, color, fontColor }) => {
  const [isMinHeight, setIsMinHeight] = useState(window.innerHeight < 621);

  window.addEventListener("resize", () => {
    if (window.innerHeight < 621) {
      if (!isMobile && !isMinHeight) {
        setIsMinHeight(true);
      }
    }

    if (window.innerHeight >= 620) {
      if (!isMobile && isMinHeight) {
        setIsMinHeight(false);
      }
    }
  });

  useEffect(() => {
    console.log({ isMinHeight });
  }, [isMinHeight]);

  return (
    <div
      className={`${
        isMobile
          ? "welcom-screen__main-block__label welcom-screen__main-block__label--mobile"
          : "welcom-screen__main-block__label"
      } ${
        !isChatLabel ? "welcom-screen__main-block__label--start-screen" : ""
      }`}
      style={!isMinHeight ? { backgroundColor: color } : {}}
    >
      <div onClick={() => onClickLabelHandler()}>
        <span
          onClick={() => onClickLabelHandler()}
          className="welcom-screen__main-block__label-text"
          style={{ color: !isMinHeight ? fontColor : "#2D77F6" }}
        >
          Ми використовуємо JediDesk{" "}
        </span>
        <Flag />
      </div>
    </div>
  );
};

export default Label;
