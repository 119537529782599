import React from "react";

const ImageModal = ({ onClose, imageUrl }) => {
  return (
    <div className="jedidesk-modal-container">
      <div className="jedidesk-modal-wrapper">
        <button
          onClick={() => onClose()}
          className="jedidesk-modal-container__close-button"
        >
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <title>remove-delete-glyph</title>
            <polygon
              points="512 86.78 425.22 0 256 169.22 86.78 0 0 86.78 169.22 256 0 425.22 86.78 512 256 342.78 425.22 511.99 512 425.22 342.78 256 512 86.78"
              fill="#ffffff"
            />
          </svg>
        </button>
        <img
          src={imageUrl}
          className="jedidesk-modal-container__image"
          alt="jedidesk-modal"
        />
      </div>
    </div>
  );
};

export default ImageModal;
