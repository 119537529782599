import React from "react";
import AvataImg from "../assets/img/avatar_placeholder.jpg";

const Avatar = ({ imgUrl, name }) => {
  return (
    <div className="chat-header__avatar-wrapper">
      <div className="chat-header__avatar-container">
        <img
          src={imgUrl ? imgUrl : AvataImg}
          style={{ height: 74, width: 74, borderRadius: 37 }}
        />
      </div>
      <div className="chat-header__avatar-manager-name-block">
        <span className="chat-header__avatar-manager-name-block-text">
          {name}
        </span>
      </div>
    </div>
  );
};

export default Avatar;
