import React from "react";

import Label from "./label";
import { getTintedColor } from "../utils/utils";
import { LIGHT_COLOR_VALUE } from "../const/const";
import Telegram from "./svg/Telegram";
import Viber from "./svg/Viber";
import RightArrow from "./svg/RightArrow";
import CloseButton from "./svg/CloseButton";

export function WelcomScreen({
  onClose,
  isMobile,
  onStartMessaging,
  widgetOptions,
  telegramBotLink,
  viberBotLink,
}) {
  const {
    color,
    fontColor,
    widgetHeadStart,
    widgetTextStart,
    dialogButtonText,
    messagesBlockHead,
    messagesBlockDescription,
  } = widgetOptions;

  const onCloseHandler = () => {
    onClose();
  };

  const onChatOpenHandler = () => {
    onStartMessaging();
  };

  const onOpenBotHandler = (url) => {
    window.open(url, "blank");
  };

  return (
    <div className="welcom-screen">
      <div
        className={`welcom-screen__head ${
          isMobile ? "welcom-screen__head--mobile" : ""
        }`}
        style={{
          background: color,
        }}
      >
        <div className="welcom-screen__head-content-wrapper">
          <div className="welcom-screen__close-button-wrapper">
            <button
              onClick={() => onCloseHandler()}
              className="welcom-screen__close-button"
            >
              <CloseButton />
            </button>
          </div>

          <div className="welcom-screen__head-title-wrapper">
            <h1
              style={{ color: fontColor }}
              className="welcom-screen__head-title"
            >
              {widgetHeadStart + " "}
            </h1>
            <div className="welcom-screen__head-title-image"></div>
          </div>
          <p
            style={{ color: fontColor, whiteSpace: "pre-line" }}
            className="welcom-screen__head-description"
            dangerouslySetInnerHTML={{ __html: widgetTextStart }}
          />
          <div className="welcom-screen__head-decor-fon"></div>
        </div>
      </div>
      <div
        className={`welcom-screen__main-block ${
          isMobile ? "welcom-screen__main-block--mobile" : ""
        }`}
      >
        <div className="welcom-screen__main-block-item welcom-screen__main-block-item--second">
          <h4
            className={`welcom-screen__main-block-item-title ${
              isMobile ? "welcom-screen__main-block-item-title--mobile" : ""
            }`}
          >
            {messagesBlockHead}
          </h4>
          <p className="welcom-screen__main-block-item-text">
            {messagesBlockDescription}
          </p>
          <div className="welcom-screen__main-block-item-messangers-links-block">
            {telegramBotLink && (
              <button
                key={"telegram"}
                className="welcom-screen__main-block-item-messangers-links-button welcom-screen__main-block-item-messangers-links-button--telegram"
                onClick={() => onOpenBotHandler(telegramBotLink)}
              >
                <Telegram telegramBotLink={telegramBotLink} />

                <span className="welcom-screen__main-block-item-messangers-links-button-text">
                  Telegram
                </span>
              </button>
            )}
            {viberBotLink && (
              <button
                key={"viber"}
                className="welcom-screen__main-block-item-messangers-links-button welcom-screen__main-block-item-messangers-links-button--viber"
                onClick={() => onOpenBotHandler(viberBotLink)}
              >
                <Viber viberBotLink={viberBotLink} />
                <span className="welcom-screen__main-block-item-messangers-links-button-text">
                  Viber
                </span>
              </button>
            )}
          </div>
        </div>
        <div className="welcom-screen__main-block-item welcom-screen__main-block-item--first">
          <div
            onClick={() => onChatOpenHandler()}
            className="welcom-screen__start-messaging"
            style={{
              backgroundColor: getTintedColor(color, LIGHT_COLOR_VALUE),
            }}
          >
            <span className="welcom-screen__dialogButton-text">
              {dialogButtonText}
            </span>
            <RightArrow />
          </div>
        </div>

        <Label
          isMobile={isMobile}
          isChatLabel={false}
          color={color}
          fontColor={fontColor}
        />
      </div>
    </div>
  );
}
