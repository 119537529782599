export const domainUrl = "https://app-jedidesk.boto.kyiv.ua/";

export const SOKET_MESSAGE_TYPES = {
  openData: "openData",
  authData: "authData",
  JWSendMessage: "JWSendMessage",
  newMessage: "newMessage",
  messages: "messages",
  JWGetMessages: "JWGetMessages",
  Unauthorized: "Unauthorized",
};

export const MESSAGES_TYPES = {
  manager: "manager",
  customer: "customer",
};

export const DATA_MESSAGES_TYPES = {
  text: "text",
  media: "media",
};

export const MEDIA_FILE_TYPES = {
  image: "image",
  file: "file",
  audio: "audio",
  video: "video",
};

export const LIGHT_COLOR_VALUE = -20;

export const jedideskSiteUrl = "http://jedidesk.com";
