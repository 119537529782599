import React from "react";
import { getTintedColor } from "../utils/utils";
import { LIGHT_COLOR_VALUE } from "../const/const";

export function HidenIcon({ onOpen, isChatOpen, isFirstOpen, widgetOptions }) {
  const onClickHidenButton = () => {
    console.log("open");
    onOpen();
  };

  const { color, fontColor } = widgetOptions;

  return (
    <div
      onClick={() => onClickHidenButton()}
      className={`hiden-icon ${
        isChatOpen ? "hiden-icon--open" : isFirstOpen ? "hiden-icon--close" : ""
      }`}
    >
      <div className="hiden-icon__svg-wrapper">
        {!isChatOpen && (
          <svg
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 161 161"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={79.5} cy={79.5} r={68.5} fill={color} />
            <circle
              opacity={0.8}
              cx={80.121}
              cy={80.121}
              r={78.121}
              stroke={color}
              strokeWidth={4}
            />
            <path
              d="M105.161 88.972c-.147-.527.176-1.26.484-1.788.094-.158.197-.31.308-.455A23.752 23.752 0 0 0 110 73.488C110.044 59.968 98.636 49 84.53 49c-12.303 0-22.568 8.373-24.972 19.488a23.561 23.561 0 0 0-.543 5.015c0 13.534 10.968 24.795 25.075 24.795 2.243 0 5.264-.674 6.92-1.129a46.437 46.437 0 0 0 3.725-1.217 3.878 3.878 0 0 1 2.845.044l8.314 2.948c.182.077.375.126.572.146a1.172 1.172 0 0 0 1.173-1.173 1.902 1.902 0 0 0-.073-.396l-2.405-8.549Z"
              stroke={fontColor}
              strokeWidth={4.816}
              strokeMiterlimit={10}
              strokeLinecap="round"
            />
            <path
              d="M51.707 75.98a21.441 21.441 0 0 0 .937 22.387c.339.512.53.908.47 1.173-.058.266-1.749 9.073-1.749 9.073a1.171 1.171 0 0 0 .398 1.126 1.2 1.2 0 0 0 .756.261c.147 0 .292-.03.427-.088l8.242-3.226a2.302 2.302 0 0 1 1.76.029c2.777 1.082 5.848 1.76 8.92 1.76a23.352 23.352 0 0 0 11.738-3.151"
              stroke={fontColor}
              strokeWidth={4.816}
              strokeMiterlimit={10}
              strokeLinecap="round"
            />
          </svg>
        )}
        {isChatOpen && (
          <svg
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 161 161"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={79.5} cy={79.5} r={68.5} fill={color} />
            <circle
              opacity={0.4}
              cx={80.121}
              cy={80.121}
              r={78.121}
              stroke={color}
              strokeWidth={4}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.113 68.726c.248 0 .495-.01.738-.031a11.24 11.24 0 0 0-.128 1.695v16.45c0 2.151-.62 5.297-2.583 8.008-2.096 2.895-5.553 5.034-10.523 5.034A3.612 3.612 0 0 1 44 96.274a3.612 3.612 0 0 1 3.617-3.607c2.621 0 3.908-1.006 4.659-2.043.883-1.22 1.213-2.791 1.213-3.784V70.39c0-1.462.17-2.884.494-4.247a8.535 8.535 0 0 0 6.13 2.583Zm11.814-9.51c-1.14 0-2.242.17-3.28.485a8.511 8.511 0 0 0-3.52-6.41 18.432 18.432 0 0 1 6.8-1.291h21.069C106.253 52 117 62.719 117 75.94c0 13.223-10.747 23.942-24.004 23.942H67.249a3.612 3.612 0 0 1-3.617-3.608 3.612 3.612 0 0 1 3.617-3.607h25.747c9.261 0 16.77-7.489 16.77-16.726 0-9.237-7.509-16.726-16.77-16.726H71.927Zm-11.814-3.608a4.598 4.598 0 0 0-4.604 4.59 4.597 4.597 0 0 0 4.604 4.592 4.597 4.597 0 0 0 4.603-4.591 4.598 4.598 0 0 0-4.603-4.591Z"
              fill={fontColor}
            />
          </svg>
        )}
      </div>
    </div>
  );
}
