import React, { useState, useRef, useEffect } from "react";

import "../App.scss";
import TextareaAutosize from "react-textarea-autosize";
import { WelcomScreen } from "./welcomScreen";
import MessageItem from "./messageItem";
import ImageModal from "./imageModal";
import Label from "./label";
import BackButton from "./svg/BackButton";
import CloseButton from "./svg/CloseButton";
import Picker from "emoji-picker-react";
import Avatar from "./Avatar";
import SendButton from "./svg/SendButton";
import WaveDecorChat from "./svg/WaveDecorChat";
import { adaptMessage, getTintedColor } from "../utils/utils";
import { DATA_MESSAGES_TYPES, LIGHT_COLOR_VALUE } from "../const/const";

const MIN_MOBILE_HEIGHT = 210;

let modalImageUrl = "";

export function Chat({
  isMobile,
  onClose,
  socket,
  widgetOptions,
  messagesList,
  telegramBotLink,
  viberBotLink,
}) {
  const {
    color,
    fontColor,
    widgetHead,
    widgetText,
    widgetAvatarText,
    managerPhoto,
  } = widgetOptions;

  const [isWelcomScreenOpen, setIsWelcomScreenOpen] = useState(true);
  const [isMinHeight, setIsMinHeight] = useState(false);
  const [isKeyboardOpen, setIsKeyBoardOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [isTextTyping, setIsTextTyping] = useState(false);
  const [headHeight, setHeadHeight] = useState(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const headRef = useRef();

  const inputText = useRef();
  const messagesListRef = useRef();
  const endElement = useRef();
  const fileInputRef = useRef();

  const onStartMessaging = () => {
    setIsWelcomScreenOpen(false);
  };

  const onBackButtonClickHandler = () => {
    setIsWelcomScreenOpen(true);
  };

  const onFocusHandler = () => {
    if (document.documentElement.clientHeight < MIN_MOBILE_HEIGHT) {
      setIsMinHeight(true);
    }

    setIsKeyBoardOpen(true);
  };

  const onFocusOut = () => {
    setIsMinHeight(false);
    setIsKeyBoardOpen(false);
  };

  window.addEventListener("resize", () => {
    if (
      isMinHeight &&
      document.documentElement.clientHeight > MIN_MOBILE_HEIGHT
    ) {
      setIsMinHeight(false);
    }

    if (
      isKeyboardOpen &&
      document.documentElement.clientHeight < MIN_MOBILE_HEIGHT
    ) {
      setIsMinHeight(true);
    }
  });

  const onInputMessageHandler = (evt) => {
    setMessage(evt.target.value);
    if (evt.target.value !== "") {
      setIsTextTyping(true);
    }

    if (evt.target.value === "") {
      setIsTextTyping(false);
    }
  };

  const onSendMessageHandler = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    if (message.trim() === "") {
      return;
    }

    sendMessage(adaptMessage(message), DATA_MESSAGES_TYPES.text);

    setMessage("");
    setIsTextTyping(false);
    inputText.current.focus();
  };

  const onSendFileHandler = () => {
    const inputFilesArray = Array.from(fileInputRef.current.files);
    console.log({ files: inputFilesArray });
    console.log(fileInputRef.current.files);

    let file = {};
    file.name = inputFilesArray[0].name;

    let reader = new FileReader();

    reader.onloadend = () => {
      file.data = reader.result;
      sendMessage(file, DATA_MESSAGES_TYPES.media);
    };

    reader.readAsDataURL(inputFilesArray[0]);
  };

  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
    setShowPicker(false);
  };

  window.addEventListener("keydown", function (e) {
    if (e.keyCode !== 13) return;

    alert("enter!");
  });

  const sendMessage = (message, type) => {
    if (type === DATA_MESSAGES_TYPES.text) {
      socket.send(
        JSON.stringify({
          action: "JWSendMessage",
          data: {
            text: message,
          },
        })
      );
    }

    if (type === DATA_MESSAGES_TYPES.media) {
      socket.send(
        JSON.stringify({
          action: "JWSendMessage",
          data: {
            media: message,
          },
        })
      );
    }
  };

  useEffect(() => {
    setHeadHeight(headRef.offsetHeight);
  }, [headRef]);

  useEffect(() => {
    socket.send(
      JSON.stringify({
        action: "JWGetMessages",
      })
    );
  }, [socket]);

  useEffect(() => {
    if (!isWelcomScreenOpen) {
      endElement.current.scrollIntoView({ behavior: "smooth" });
      console.log({ endElement });
    }
  });

  if (isWelcomScreenOpen) {
    return (
      <WelcomScreen
        isMobile={isMobile}
        onClose={() => onClose()}
        onStartMessaging={() => onStartMessaging()}
        widgetOptions={widgetOptions}
        telegramBotLink={telegramBotLink}
        viberBotLink={viberBotLink}
      />
    );
  }

  const onOpenModalHandler = (imageUrl) => {
    modalImageUrl = imageUrl;
    setIsImageModalOpen(true);
  };

  const onCloseModalHandler = () => {
    setIsImageModalOpen(false);
  };

  return (
    <div className="jedidesk-chat__wraper">
      <div
        ref={headRef}
        className={`jedidesk-chat-head  ${
          isMobile ? "jedidesk-chat-head--mobile" : ""
        }`}
        style={{
          background: color,
          color: fontColor,
        }}
      >
        <div className="chat-screen__head-content-wrapper">
          <div className="jedidesk-chat-head_button-container">
            <button
              onClick={() => onBackButtonClickHandler()}
              className="jedidesk-chat__back-button"
            >
              <BackButton />
            </button>

            <button
              onClick={() => onClose()}
              className="welcom-screen__close-button"
            >
              <CloseButton />
            </button>
          </div>
          <div className="jedidesk-chat__personal-info">
            <Avatar imgUrl={managerPhoto} name={widgetAvatarText} />
            <div className="jedidesk-chat__personal-wrapper">
              <p
                style={{ color: fontColor }}
                className={`jedidesk-chat-head_description-text`}
              >
                {widgetHead}
              </p>
              <div className="jedidesk-chat__manager-info">
                <p
                  className="jedidesk-chat__manager-info-name"
                  style={{ color: fontColor, whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{ __html: widgetText }}
                />
              </div>
            </div>
          </div>
          <div className="welcom-screen__head-decor-fon-chat"></div>
          <div className="welcom-screen__head-date-start-container">
            <span className="welcom-screen__head-date-start-container-text">
              15 Квітня, 2022
            </span>
          </div>
        </div>
      </div>
      <div
        className={`jedidesk-chat__mesages-area ${
          isMobile
            ? "jedidesk-chat__mesages-area--mobile"
            : "jedidesk-chat__mesages-area--desktop"
        }`}
      >
        <div
          id="block"
          ref={messagesListRef}
          className={`jedidesk-chat__mesages-area-items-list ${
            isMobile ? "jedidesk-chat__mesages-area-items-list--mobile" : ""
          }`}
          style={{ paddingTop: `${headHeight + 20}px` }}
        >
          {messagesList.map((item, index) => {
            return (
              <MessageItem
                message={item}
                color={color}
                fontColor={fontColor}
                isLastMessage={index === messagesList.length - 1}
                isMobile={isMobile}
                onOpenImageModal={(imageUrl) => onOpenModalHandler(imageUrl)}
                key={item.id}
              />
            );
          })}

          <div ref={endElement}></div>
        </div>
        <div className="picker-container">
          {showPicker && (
            <Picker
              pickerStyle={{ width: "100%" }}
              onEmojiClick={onEmojiClick}
            />
          )}
        </div>
        <div className="jedidesk-chat__form-wrapper">
          <form
            className={`jedidesk-chat__form ${
              isMobile ? "jedidesk-chat__form--mobile" : ""
            }`}
          >
            <div className="jedidesk-chat__form-item-wrapper">
              <TextareaAutosize
                className="jedidesk-chat__new-message"
                placeholder="Напишіть Ваше питання..."
                onFocus={() => onFocusHandler()}
                onBlur={() => onFocusOut()}
                onChange={(evt) => onInputMessageHandler(evt)}
                value={message}
                ref={inputText}
                onKeyDown={(evt) => {
                  if (evt.keyCode === 13 && !evt.shiftKey && !isMobile) {
                    onSendMessageHandler(evt);
                  }
                }}
              ></TextareaAutosize>
              <div className="jedidesk-chat__form-buttons">
                {!isMobile && (
                  <div
                    className="emoji-icon"
                    onClick={() => setShowPicker((val) => !val)}
                  >
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM12.998 19c1.893 0 3.486-1.477 3.99-3.492a.478.478 0 0 0-.057-.352.355.355 0 0 0-.12-.116.288.288 0 0 0-.15-.04H9.338a.288.288 0 0 0-.15.04.355.355 0 0 0-.119.116.477.477 0 0 0-.057.352c.5 2.015 2.093 3.492 3.986 3.492ZM9.5 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                        fill="#969696"
                      />
                      <path
                        d="M13 22a9 9 0 1 1 0-18 9 9 0 0 1 0 18Z"
                        stroke="#969696"
                        strokeWidth={1.5}
                        strokeMiterlimit={10}
                      />
                    </svg>
                  </div>

                  // <img
                  //   className="emoji-icon"
                  //   src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                  //   onClick={() => setShowPicker((val) => !val)}
                  //   alt="emoji-icon"
                  // />
                )}
                {!isTextTyping && (
                  <label className="jedidesk-chat__form-file-label">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.973 9.75v7.305a2.036 2.036 0 0 0 4.07 0l.007-9.575a3.45 3.45 0 1 0-6.9 0v9.64a4.85 4.85 0 1 0 9.7 0V8.111"
                        stroke="#969696"
                        strokeWidth={1.5}
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                      />
                    </svg>
                    <input
                      ref={fileInputRef}
                      onChange={() => onSendFileHandler()}
                      className="jedidesk-chat__form-file-input"
                      type="file"
                    ></input>
                  </label>
                )}
                <SendButton onClick={() => onSendMessageHandler()} />
              </div>
            </div>
          </form>
        </div>
      </div>
      {isImageModalOpen && (
        <ImageModal
          onClose={() => onCloseModalHandler()}
          imageUrl={modalImageUrl}
        />
      )}
    </div>
  );
}
