import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={15}
    height={10}
    viewBox="0 0 256 256"
    xmlSpace="preserve"
    {...props}
  >
    <defs>
      <linearGradient
        x1={87.397}
        y1={141.973}
        x2={240.947}
        y2={141.973}
        id="c"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-129.37 -94.568) scale(1.56769)"
      >
        <stop
          style={{
            stopColor: "#fff",
            stopOpacity: 0.66666675,
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#d7d7d7",
            stopOpacity: 0.21875,
          }}
          offset={0.241}
        />
        <stop
          style={{
            stopColor: "#4d4d4d",
            stopOpacity: 0,
          }}
          offset={0.459}
        />
        <stop
          style={{
            stopColor: "#fff",
            stopOpacity: 0.51041669,
          }}
          offset={0.719}
        />
        <stop
          style={{
            stopColor: "#f2f2f2",
            stopOpacity: 0.40000001,
          }}
          offset={0.874}
        />
        <stop
          style={{
            stopColor: "#fff",
            stopOpacity: 0.62352943,
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        gradientTransform="translate(-69.826 15.227) scale(1.56769)"
        gradientUnits="userSpaceOnUse"
        id="a"
        y2={112.305}
        x2={126.189}
        y1={39.618}
        x1={126.189}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#c200be",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.195}
          style={{
            stopColor: "#c200be",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.315}
          style={{
            stopColor: "#1000ae",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.73}
          style={{
            stopColor: "#1000ae",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.857}
          style={{
            stopColor: "#00dab6",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#00dab6",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient id="b">
        <stop
          offset={0}
          style={{
            stopColor: "#ffd500",
            stopOpacity: 1,
          }}
        />
        <stop
          style={{
            stopColor: "#ffd500",
            stopOpacity: 1,
          }}
          offset={0.568}
        />
        <stop
          style={{
            stopColor: "#005bbb",
            stopOpacity: 1,
          }}
          offset={0.697}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#005bbb",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#c"
        id="e"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-129.371 -94.57) scale(1.56769)"
        x1={87.397}
        y1={141.973}
        x2={269.333}
        y2={141.973}
      />
      <linearGradient
        xlinkHref="#b"
        id="d"
        gradientUnits="userSpaceOnUse"
        x1={128}
        y1={193.736}
        x2={125.549}
        y2={90.252}
      />
    </defs>
    <path
      style={{
        fill: "none",
      }}
      d="M0 256V0h256v256"
    />
    <path
      d="M248.36 32.472C167.273-10.43 86.184 85.849 5.096 40.005 2.281 38.417 0 39.213 0 42.03v171.94c0 2.811 2.281 6.58 5.095 8.165 81.936 46.325 163.874-52.464 245.81-6.14 2.814 1.587 5.095.793 5.095-2.024V42.03c0-2.811-2.281-6.578-5.095-8.166-.848-.479-1.696-.943-2.544-1.392z"
      style={{
        fill: "#4d4d4d",
        fillOpacity: 1,
      }}
    />
    <path
      d="M248.36 37.567c0 1.854-14.891-10.205-37.309-11.054-22.416-2.352-52.355 6.506-82.452 15.426C98.502 50.84 68.251 59.83 45.21 57.5 22.17 56.646 6.343 44.472 5.095 45.1v171.941c1.235-.802 16.907 11.481 39.798 12.395 22.89 2.4 52.999-6.569 83.107-15.507 30.11-8.93 60.219-17.857 83.108-15.438 22.89.933 38.563 13.21 39.797 12.41V38.958c-1.274-.722-2.544-1.392-2.544-1.392z"
      style={{
        fill: "url(#d)",
        fillOpacity: 1,
      }}
    />
    <path
      d="M7.641 107.332c80.24 40.703 160.48-49.264 240.718-8.56V57.83C168.119 17.124 87.88 107.091 7.641 66.39v40.943z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
      }}
    />
    <path
      d="M248.36 206.96c-80.24-42.452-160.478 51.376-240.717 8.927V49.041c80.239 42.45 160.479-51.379 240.718-8.926V206.96z"
      style={{
        fill: "#ffd500",
        fillOpacity: 1,
      }}
    />
    <path
      d="M203.2 28.8C138.005 28.06 72.794 82.496 7.6 48.667V132.4c80.24 42.855 160.561-51.926 240.8-9.067V39.867c-15.045-7.808-30.155-10.896-45.2-11.067z"
      style={{
        fill: "#005bbb",
        fillOpacity: 1,
      }}
    />
    <path
      d="M248.36 206.96c-80.24-42.452-160.48 51.377-240.72 8.925V49.04c80.24 42.45 160.48-51.378 240.72-8.925V206.96z"
      style={{
        fill: "url(#e)",
      }}
    />
  </svg>
);

export default SvgComponent;
