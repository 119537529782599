import "./App.scss";
import { ChatContainer } from "./components/chatContainer";

function App() {
  return (
    <div className="App">
      <ChatContainer />
    </div>
  );
}

export default App;
