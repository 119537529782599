import React, { useState, useCallback, useEffect } from "react";
import Frame from "react-frame-component";
import { Chat } from "./chat";
import { HidenIcon } from "./hidenIcon";
import { TokenService } from "../service/token/storage.service";
import { MESSAGES_TYPES, SOKET_MESSAGE_TYPES } from "../const/const";
import { formatDate, pingPong } from "../utils/utils";
import { useWindowHeight } from "@react-hook/window-size";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const BASE_DOMAIN_URL = process.env.REACT_APP_BASE_DOMAIN_URL;

let audio = new Audio(`${BASE_DOMAIN_URL}/assets/sounds/sentmessage.mp3`);

console.log({ BASE_DOMAIN_URL: BASE_DOMAIN_URL });

let socket = new WebSocket(SOCKET_URL);

const sendSocetAuth = () => {
  socket.onopen = () => {
    socket.send(
      JSON.stringify({
        action: "setOpenData",
        t: "c",
        jw_token: window.JWidgetToken,
      })
    );

    console.log(
      JSON.stringify({
        action: "setOpenData",
        t: "c",
        jw_token: window.JWidgetToken,
      })
    );
    socket.send(JSON.stringify({ action: "JWAuth" }));
    console.log("Соединение установлено");
    pingPong(socket);
  };
};

export function ChatContainer() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [widgetOptions, setWidgetOptions] = useState({
    color: "#438DF6",
    fontColor: "#ffffff",
    managerPhoto: `${BASE_DOMAIN_URL}/assets/img/avatar_placeholder.jpg`,
    welcomMessage: "Привіт.\nЯке в тебе питання?",
    widgetHead: "У вас є запитання?",
    widgetText: "Звичайний час\nвідповіді 5 хвилин",
    widgetHeadStart: "Привіт",
    widgetTextStart: "Готова система турботи для ваших клієнтів за 30 хвилин",
    dialogButtonText: "Написати нам",
    widgetAvatarText: "Оператор",
    messagesBlockHead: "Зв'язок через месенджери",
    messagesBlockDescription:
      "Ви можете зв'язатися з нами через зручний месенджер або відправити повідомлення в чаті праворуч тут!",
  });
  const [telegramBotLink, setTelegramBotLink] = useState(null);
  const [viberBotLink, setViberBotLink] = useState(null);
  const [messagesList, setMessagesList] = useState([
    {
      id: 0,
      from: MESSAGES_TYPES.manager,
      media: null,
      media_type: null,
      text: widgetOptions.welcomMessage,
      time: formatDate(new Date()),
    },
  ]);

  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth < 750
  );

  const windowHeight = useWindowHeight();

  const onOpenChatHandler = () => {
    setIsChatOpen(!isChatOpen);
    if (isFirstOpen) {
      setIsFirstOpen(false);
    }
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 750) {
      if (!isMobile) {
        setIsMobile(true);
      }
    }

    if (window.innerWidth >= 750) {
      if (isMobile) {
        setIsMobile(false);
      }
    }
  });

  // window.addEventListener(
  //   "orientationchange",
  //   function () {
  //     console.log("orientation change");
  //     setDocumentSize({
  //       with: document.documentElement.clientWidth,
  //       height: document.documentElement.clientHeight,
  //     });
  //   },
  //   false
  // );

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.type === SOKET_MESSAGE_TYPES.Unauthorized) {
      socket.close();
      socket = new WebSocket(SOCKET_URL);
      TokenService.deleteCustomerIdTocken();
    }

    if (data.type === SOKET_MESSAGE_TYPES.openData) {
      const widgetOptionsBack = JSON.parse(data.data.widgetOptions);

      console.log({ widgetOptionsBack });
      if (
        widgetOptionsBack !== null &&
        Object.keys(widgetOptionsBack).length > 0
      ) {
        setWidgetOptions({
          color:
            widgetOptionsBack.color !== null
              ? widgetOptionsBack.color
              : widgetOptions.color,
          fontColor:
            widgetOptionsBack.fontColor !== null
              ? widgetOptionsBack.fontColor
              : widgetOptions.fontColor,
          managerPhoto:
            widgetOptionsBack.managerPhoto !== null
              ? widgetOptionsBack.managerPhoto
              : widgetOptions.managerPhoto,
          welcomMessage:
            widgetOptionsBack.welcomMessage !== null
              ? widgetOptionsBack.welcomMessage
              : widgetOptions.welcomMessage,
          widgetHead:
            widgetOptionsBack.widgetHead !== null
              ? widgetOptionsBack.widgetHead
              : widgetOptions.widgetHead,
          widgetText:
            widgetOptionsBack.widgetText !== null
              ? widgetOptionsBack.widgetText
              : widgetOptions.widgetText,
          widgetHeadStart: widgetOptionsBack.widgetHeadStart
            ? widgetOptionsBack.widgetHeadStart
            : widgetOptions.widgetHeadStart,
          widgetTextStart: widgetOptionsBack.widgetTextStart
            ? widgetOptionsBack.widgetTextStart
            : widgetOptions.widgetTextStart,
          dialogButtonText: widgetOptionsBack.dialogButtonText
            ? widgetOptionsBack.dialogButtonText
            : widgetOptions.dialogButtonText,
          widgetAvatarText: widgetOptionsBack.widgetAvatarText
            ? widgetOptionsBack.widgetAvatarText
            : widgetOptions.widgetAvatarText,
          messagesBlockHead: widgetOptionsBack.messagesBlockHead
            ? widgetOptionsBack.messagesBlockHead
            : widgetOptions.messagesBlockHead,
          messagesBlockDescription: widgetOptionsBack.messagesBlockDescription
            ? widgetOptionsBack.messagesBlockDescription
            : widgetOptions.messagesBlockDescription,
        });
      }
      console.log({ widgetOptions });

      if (data.data.telegramBotLink) {
        setTelegramBotLink(data.data.telegramBotLink);
      }

      if (data.data.viberBotLink) {
        setViberBotLink(data.data.viberBotLink);
      }
    }
    if (data.type === SOKET_MESSAGE_TYPES.authData) {
      TokenService.setCustomerIdToken(data.c_id);
    }

    if (data.type === SOKET_MESSAGE_TYPES.messages) {
      setMessagesList([messagesList[0], ...data.data]);
    }

    if (data.type === SOKET_MESSAGE_TYPES.newMessage) {
      setMessagesList([...messagesList, data.data]);
      audio.play();
    }
  };

  useEffect(() => {
    if (TokenService.getCustomerIdTocken() === null) {
      sendSocetAuth();
    }

    if (TokenService.getCustomerIdTocken() !== null) {
      console.log({ socket });
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            action: "setOpenData",
            t: "c",
            jw_token: window.JWidgetToken,
            c_id: TokenService.getCustomerIdTocken(),
          })
        );

        console.log("Соединение установлено");
        pingPong(socket);
      };
    }

    console.log({ token: TokenService.getCustomerIdTocken() });
  }, [socket]);

  return (
    <>
      {!isChatOpen && (
        <div className="jedidesk-chat__position">
          <div className="jedidesk-frame-position jedidesk-frame-position--close">
            <Frame
              frameBorder="none"
              width="90px"
              height="90px"
              initialContent={`<!DOCTYPE html><html><link rel=stylesheet href=${BASE_DOMAIN_URL}/mysite.css><head></head><body><div></div></body></html>`}
            >
              {/* <Frame
              frameBorder="none"
              width="90px"
              height="90px"
              initialContent={`<!DOCTYPE html><html><link rel=stylesheet href=./mysite.css><head></head><body><div></div></body></html>`}
            > */}
              {widgetOptions !== null && (
                <div className="frame__chat-wrapper--close">
                  <HidenIcon
                    onOpen={() => onOpenChatHandler()}
                    isChatOpen={isChatOpen}
                    isFirstOpen={isFirstOpen}
                    widgetOptions={widgetOptions}
                  />
                </div>
              )}
            </Frame>
          </div>
        </div>
      )}

      {isChatOpen && (
        <div className="jedidesk-chat__position jedidesk-chat__position--open">
          <div className="jedidesk-frame-position">
            <Frame
              id="jedidesk-iframe"
              initialContent={`<!DOCTYPE html><html><meta name=viewport content=width=device-width, initial-scale=1, maximum-scale=1 /><link rel=stylesheet href=${BASE_DOMAIN_URL}/mysite.css><head></head><body><div></div></body></html>`}
            >
              {/* <Frame
              id="jedidesk-iframe"
              initialContent="<!DOCTYPE html><html><meta name=viewport content=width=device-width, initial-scale=1, maximum-scale=1 /><link rel=stylesheet href=./mysite.css><head></head><body><div></div></body></html>"
            > */}
              {/* <div className="test-div"></div> */}
              {/* <Chat /> */}

              <div className="frame__chat-container-wrapper">
                <Chat
                  isMobile={isMobile}
                  onClose={() => onOpenChatHandler()}
                  socket={socket}
                  widgetOptions={widgetOptions}
                  messagesList={messagesList}
                  telegramBotLink={telegramBotLink}
                  viberBotLink={viberBotLink}
                />
              </div>
            </Frame>
          </div>
        </div>
      )}
    </>
  );
}
