import React, { useCallback } from "react";
import "../App.scss";
import {
  MESSAGES_TYPES,
  LIGHT_COLOR_VALUE,
  MEDIA_FILE_TYPES,
} from "../const/const";

import { getTintedColor, openFile, getFileName } from "../utils/utils";

const REACT_APP_JD_DOMAIN_URL = process.env.REACT_APP_JD_DOMAIN_URL;

const MessageItem = ({
  message,
  color,
  fontColor,
  isLastMessage,
  isMobile,
  onOpenImageModal,
}) => {
  const onClickImageHandler = useCallback(
    (imageUrl) => {
      if (isMobile) {
        onOpenImageModal(imageUrl);
      }

      if (!isMobile) {
        openFile(imageUrl);
      }
    },
    [isMobile, onOpenImageModal]
  );

  const ContentRenderer = useCallback(
    (item) => {
      if (
        item.text !== null &&
        (item.media === null || item.media === "") &&
        item.text.length > 0
      ) {
        return (
          <>
            <p
              className="jedidesk-chat__mesages-area-item-text"
              dangerouslySetInnerHTML={{ __html: item.text }}
              style={{ whiteSpace: "pre-line" }}
            />
            <div className="jedidesk-chat__mesages-area-item-time-container">
              <div className="jedidesk-chat__mesages-area-item-time-container-text">
                {item.time.split(" ").pop()}
              </div>
            </div>
          </>
        );
      }

      if (item.media_type === MEDIA_FILE_TYPES.image) {
        return (
          <>
            <img
              onClick={() =>
                onClickImageHandler(REACT_APP_JD_DOMAIN_URL + item.media)
              }
              src={REACT_APP_JD_DOMAIN_URL + item.media}
              className="jedidesk-chat__mesages-area-item-image"
              alt="jedidesk-chat__mesages-area-item"
            />
            <div className="jedidesk-chat__mesages-area-item-time-container">
              <div className="jedidesk-chat__mesages-area-item-time-container-text">
                {item.time.split(" ").pop()}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              onClick={() => openFile(REACT_APP_JD_DOMAIN_URL + item.media)}
              className="jedidesk-chat__mesages-file-name-container"
            >
              <div className="jedidesk-chat__mesages-file-name-container-svg">
                <svg focusable="false" aria-hidden="true" viewBox="0 0 16 18">
                  <path
                    d="M14.154 6.918l-.004.003.001-.004-3.287 3.286-.006-.005-3.574 3.574c-.016.017-.03.036-.048.053l-.05.047-.043.041v-.002c-1.167 1.07-2.692 1.331-3.823.2-1.13-1.13-.89-2.677.18-3.843l-.005-.004.074-.073.016-.018c.006-.005.012-.009.017-.016l6.053-6.053.761.76-6.053 6.054-.029.028v.001l-.005.004-.073.074c.011-.01.025-.018.035-.03-.688.75-.93 1.636-.21 2.356.72.72 1.583.456 2.333-.232l-.03.034.04-.042.01-.008.008-.009.033-.03.031-.034.01-.009.007-.009 5.004-5.003.005.006 1.858-1.859c1.223-1.218 1.51-2.913.291-4.132C12.462.806 10.414.74 9.195 1.958L2.248 8.905c.003 0 .006-.002.008-.004-1.625 1.667-1.542 4.43.103 6.074 1.646 1.646 4.474 1.795 6.141.17-.003.002-.004.008-.008.012l.047-.047 6.053-6.054.042-.042.743.78-.025.021.001.002-6.05 6.05-.002.002-.002.001-.046.046h-.002c-2.094 2.04-5.578 1.894-7.652-.18-2.049-2.049-2.15-5.407-.183-7.505l-.006-.005h-.002l.076-.078 6.943-6.944.003-.002.004-.005c1.641-1.64 4.367-1.574 6.008.066 1.64 1.642 1.353 4.014-.288 5.655z"
                    fillRule="evenodd"
                    fill={fontColor}
                  ></path>
                </svg>
              </div>

              <p
                className="jedidesk-chat__mesages-area-item-text"
                dangerouslySetInnerHTML={{ __html: getFileName(item.media) }}
                style={{ whiteSpace: "pre-line", color: fontColor }}
              />
            </div>
            <p
              className="jedidesk-chat__mesages-area-item-text"
              dangerouslySetInnerHTML={{ __html: item.text }}
              style={{ whiteSpace: "pre-line" }}
            />
            <div className="jedidesk-chat__mesages-area-item-time-container">
              <div className="jedidesk-chat__mesages-area-item-time-container-text">
                {item.time.split(" ").pop()}
              </div>
            </div>
          </>
        );
      }
    },
    [fontColor, onClickImageHandler]
  );
  return (
    <div
      className={`jedidesk-chat__mesages-area-block ${
        message.from === MESSAGES_TYPES.manager
          ? "jedidesk-chat__mesages-area-block-manager"
          : ""
      }`}
    >
      <div
        className={`jedidesk-chat__mesages-area-item ${
          message.from === MESSAGES_TYPES.manager
            ? "jedidesk-chat__mesages-area-item-manager"
            : ""
        }`}
      >
        {ContentRenderer(message)}
      </div>
    </div>
  );
};

export default MessageItem;
