const CUSTOMER_ID = "jw_id";

export const TokenService = {
  setCustomerIdToken(widgetToken) {
    localStorage.setItem(CUSTOMER_ID, widgetToken);
  },

  getCustomerIdTocken() {
    return localStorage.getItem(CUSTOMER_ID);
  },

  deleteCustomerIdTocken() {
    localStorage.removeItem(CUSTOMER_ID);
  },
};
